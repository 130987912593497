<template>
  <article class="article" :id="uid">
    <h3 class="title">
      <span class="back-button" @click="goBack"> Units > </span>
      {{title || name}}
    </h3>
    <section v-for="(module, index) in sortedModules" :key="index">
      <template v-if="editing">
        <div>{{ module.uid }}: {{ module.order }}
          <input v-model="orderData[module.uid].order" />
        </div>
      </template>
      <component :is="'lc-'+module.type" :classId="classId" :key="module.uid" v-bind="module" />
    </section>
    <v-btn @click="outputOrder()" v-if="editing">Output Order</v-btn>
  </article>
</template>

<script>
  // eslint-disable vue/no-side-effects-in-computed-properties
  import LcText from "./lc-text"
  import LcImage from "./lc-image"
  import LcVideo from "./lc-video"
  import LcFile from "./lc-file"

  export default {
    name: 'LcArticle',
    data: () => ({
      orderData : {}
    }),
    components: {
      LcImage,
      LcText,
      LcVideo,
      LcFile
    },
    props: {
      modules: Array,
      name: String,
      slug: String,
      uid: String,
      title: String,
      classId: String
    },
    computed: {
      sortedModules () {
        return this.modules && this.modules.length ? this.modules.map(m => {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.orderData[m.uid] =  {
            order: m.order || 0,
            path: `classes/${window.classComp.classId}/units/${window.classComp.unit.uid}/modules`
          }
          return {...m, order: m.order || 0}
        }).sort((a,b) => a.order - b.order) : []
      },
      admin () {
        return this.$store.getters.isAdmin
      },
      editing () {
        return this.admin && this.$store.getters.isEditing
      }
    },
    methods: {
      goBack() {
        this.$emit('back')
      },
      outputOrder () {
        const data = {...this.orderData}
        Object.keys(data).forEach(k => (data[k].order = parseInt(data[k].order)))
        console.log(JSON.stringify(data))
      }
    }
  }
</script>

<style lang="scss" scoped>
  .article {
    padding: 10px;
  }

  .back-button {
    cursor: pointer;
    color: transparentize(#444, 0.5);
    margin-right: 10px;

    &:hover {
      color: #444;
    }
  }
</style>
