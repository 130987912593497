<template>
  <div v-html="content"></div>
</template>

<script>
export default {
  name: "LcText",
  props: {
    content: String
  }
};
</script>