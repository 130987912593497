<template>
  <div class="image-cont">
    <img :src="imgUrl" v-show="loaded" @error="errored()" ref="image"/>
    <div v-show="!loaded" class="loaded-wrapper">
      <div class="activity"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LcImage",
  props: {
    url: String,
    classId: String
  },
  data: () => ({
    loaded: false,
    imgUrl: null
  }),
  mounted() {
    if (this.url.startsWith('http://www.inkiequill.com/wp-content/') || this.url.startsWith('http://www.inkiequill.com/media/')) {
      this.getFromStorage();
    } else if (this.url.includes('https://') || this.url.includes('http://')) {
      this.loaded = true;
      this.imgUrl = this.url
    } else {
      this.getFromStorage();
    }
  },
  methods: {
    errored () {
      this.loaded = false
    },
    getFromStorage() {
      let imgPath = this.url.split('/')
      imgPath = imgPath[imgPath.length-1]
      this.$fb_storage.ref().child(`classes/${this.classId}/${imgPath}`).getDownloadURL().then(url => {
        this.imgUrl = url;
        this.loaded = true;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
  .image-cont {
    width: 100%;
    margin: 20px 0;
    text-align: center;

    img {
      width: 100%;
      height: auto;
      max-width: 1100px;
    }
  }

  .load-wrapper{
    position: relative;
    height: 100%;
    min-height: 300px;
    width: 100%;
    background-color: rgb(211,211,211);
    z-index: 44;
    overflow: hidden;
    border-radius: 5px;

     @media(max-width: 480px) {
       min-height: 150px;
     }

    .activity{
      position: absolute;
      left: -45%;
      height: 100%;
      width: 45%;
      background-image: linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
      background-image: -moz-linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
      background-image: -webkit-linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
      animation: loading 1s infinite;
      z-index: 45;
    }
  }

  @keyframes loading {
    0%{
      left: -45%;
    }
    100%{
      left: 100%;
    }
  }
</style>
