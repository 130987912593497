<template>
  <div class="video-cont">
    <template v-if="video_type === 'vimeo'">
      <iframe :src="vimeoSrc" width="640" height="360" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </template>
    <template v-else-if="video_type === 'youtube'">
      <iframe width="560" height="315" :src="youtubeSrc" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </template>
    <template v-else-if="video_type === 'custom'">
      <video controls>
        <source :src="id"/>
        <p>If you are seeing this, you need to update your browser!<br/> Try chrome ;)</p>
      </video>
    </template>
  </div>
</template>

<script>
  export default {
    name: "LcVideo",
    props: {
      id: String,
      video_type: String
    },
    computed: {
      youtubeSrc () {
        return `https://www.youtube.com/embed/${this.id}`
      },
      vimeoSrc () {
        return `https://player.vimeo.com/video/${this.id}`
      }
    }
  }
</script>

<style lang="scss" scoped>
  .video-cont {
    margin: 20px;
    text-align: center;
  }
</style>