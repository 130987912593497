<template>
  <div class="file">
    <p>Lesson file - <a :href="fileUrl" download class="btn" target="_blank">{{ title || 'Download' }}</a></p>
  </div>
</template>

<script>
  export default {
    name: 'LcFile',
    props: {
      url: String,
      title: String,
      classId: String
    },
    data: () => ({
      fileUrl: null
    }),
    mounted () {
      if (this.url.startsWith('http://www.inkiequill.com/wp-content/') || this.url.startsWith('http://www.inkiequill.com/media/')) {
        this.getFromStorage();
      } else if (this.url.includes('https://') || this.url.includes('http://')) {
        this.loaded = true;
        this.fileUrl = this.url
      } else {
        this.getFromStorage();
      }
    },
    methods: {
      getFromStorage() {
        let file = this.url.split('/')
        file = file[file.length-1]
        this.$fb_storage.ref().child(`classes/${this.classId}/${file}`).getDownloadURL().then(url => {
          this.fileUrl = url;
          this.loaded = true;
        })
      }
    }
  }
</script>
