<template>
  <v-container>
    <v-card class="article-cont" :loading="loading">
      <v-card-title class="center">{{ classObj.name }}</v-card-title>
      <template v-if="!loading && units && units.length && unit === null">
        <h2 class="center">Units</h2>
        <v-list class="units-list">
          <v-list-item v-for="(unit, uIndex) in units" :key="unit.uid" @click="selectedUnitIndex = uIndex" class="unit-item">{{ unit.name }}
          </v-list-item>
        </v-list>
      </template>
      <template v-else-if="!loading && unit && selectedUnitIndex !== null">
        <lc-article v-bind="unit" :classId="classId" @back="selectedUnitIndex = null"/>
        <v-row justify="space-around">
          <v-col md="4" sm="6" align="left">
            <v-btn v-if="prevUnit !== null" @click="changeUnit('prev')"><v-icon>chevron_left</v-icon>{{ prevUnit.name }}</v-btn>
          </v-col>
          <v-col md="4" sm="5" align="right">
            <v-btn v-if="nextUnit !== null" @click="changeUnit('next')">{{ nextUnit.name }}
              <v-icon>chevron_right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <v-card class="center padded" v-else-if="!loading && startedLoading && classId === null">
        <h1>
          404 </h1>
        <p>Not sure how we got here, it's looking a little sparse out there...</p>
        <v-row>
          <v-col>
            <v-btn :to="{name: 'home'}" :outlined="true" :large="true">Go Back Home</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import LcArticle from '../components/lc-article'

export default {
  name: 'SingleClass',
  components: { LcArticle },
  data: () => ({
    selectedUnitIndex: null,
    startedLoading: false,
    isOld: false
  }),
  mounted () {
    this.startedLoading = true
    setTimeout(() => {
      this.$store.commit('doneLoading')
    }, 20000) // if we don't get back in 20 seconds 404
    window.classComp = this
  },
  computed: {
    loading () {
      return this.$store.getters.loading ? (this.$store.getters.loading.action === 'getClassUnits' || this.$store.getters.loading.action === 'getClassUnitModules') : false
    },
    classId () {
      return this.$store.getters.classes.length ? this.$store.getters.classes.find(c => c.slug === this.$route.params.slug).uid : null
    },
    classObj () {
      return this.$store.getters.classData(this.classId)
    },
    units () {
      return this.classObj.unitsFetched ? this.classObj.units : []
    },
    unit () {
      return this.selectedUnitIndex !== null && this.units && this.units.length ? this.units[this.selectedUnitIndex] : null
    },
    prevUnit () {
      return this.selectedUnitIndex !== null && this.units && this.units.length && this.selectedUnitIndex > 0 ? this.units[this.selectedUnitIndex - 1] : null
    },
    nextUnit () {
      return this.selectedUnitIndex !== null && this.units && this.units.length && this.selectedUnitIndex + 1 < this.units.length ? this.units[this.selectedUnitIndex + 1] : null
    }
  },
  methods: {
    changeUnit (dir) {
      window.scrollTo(window.scrollX, 0)
      this.selectedUnitIndex = dir === 'next' ? this.selectedUnitIndex + 1 : this.selectedUnitIndex - 1
    }
  },
  watch: {
    classId (val) {
      if ( val ) {
        this.$store.dispatch('getClassUnits', val)
        this.isOld = val && this.$store.getters.classes ? new Date().getTime() - this.$store.getters.classData(val).unitsFetched > 3600 : false
      }
    }
  }
}
</script>

<style scoped>
.unit-item {
  background: #eee;
  border-bottom: solid 1px #fff;
}

.unit-item:hover {
  font-weight: bold;
}
</style>
